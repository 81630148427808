import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllBikers } from '../../redux/actions/Bikers';
import UpdateOffDays from '../Modals/UpdateOffDays';
import DeleteOffDays from '../Modals/DeleteOffDays';
const HolidayHistory = () => {



    var adminLogin = {};

    if (localStorage.getItem('user')) {
        adminLogin = JSON.parse(localStorage.getItem('user'));
    }


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllBikers());
    }, [dispatch])
    const BikersName = useSelector(state => state.GetBikers.AllBikers)

    function differenceInDays(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // return diffDays;
        // console.log(diffDays);



        if(!isNaN(diffDays)){
            return diffDays
        }else{
            return 'لايوجد مده'
        }
    }
    return (
        <div>
            <div className='font'>
                <div className='mt-3'>
                    <div className='bikerEditTop'>
                        سجل الاجازات السابقة
                    </div>
                    <div className='mt-3'>
                        <Table bordered >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>اسم البايكر</th>
                                    <th>تاريخ البدء</th>
                                    <th>تاريخ الانتهاء</th>
                                    <th>المسؤول</th>
                                    <th>المدة</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    BikersName && BikersName.bikers ? (
                                        BikersName.bikers.map((biker, index) => {
                                            const weekdays = ["الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت", "الأحد"];
                                            const startDate = biker.in_active_start_date ? biker.in_active_start_date.split('T')[0] : 'لايوجد معاد';
                                            const endDate = biker.in_active_end_date ? biker.in_active_end_date.split('T')[0] : 'لايوجد معاد';

                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{biker.user.first_name}</td>
                                                    {/* <td>{biker.user_id}</td> */}
                                                    <td>{startDate}</td>
                                                    <td>{endDate}</td>
                                                    <td>
                                                        {adminLogin && adminLogin.first_name && adminLogin.first_name.trim() !== "" ? adminLogin.first_name : "اسم المسؤول غير متوفر"}
                                                    </td>
                                                    <td>
                                                        <td>{differenceInDays(startDate, endDate)}</td>
                                                    </td>
                                                    {
                                                        biker.user.phone !== "user deleted" && biker.deleted_at === null ? (
                                                            <>
                                                                <td>
                                                                    {/* <UpdateOffDays selectID={biker.user_id} /> */}
                                                                </td>
                                                                <td style={{ cursor: "pointer" }}>
                                                                    {/* <DeleteOffDays selectID={biker.user_id} /> */}
                                                                </td>
                                                            </>
                                                        ) : <td colSpan={2}></td>
                                                    }
                                                </tr>
                                            );
                                        })
                                    ) : null
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default HolidayHistory
