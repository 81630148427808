import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux'
import MapManage from '../../Utils/MapManage';
import { Link } from 'react-router-dom';
import { DeleteArea, GetAllAreas, UpdateArea } from '../../redux/actions/AreaActions';
import DeletedArea from '../Modals/DeletedArea';
import UpdateAreaData from './UpdateArea';
const Area = () => {
    const [areas, setAreas] = useState([]);
    const [mapKey, setMapKey] = useState(0); // مفتاح لإعادة تقديم MapManage

    useEffect(() => {
        getAreas();
    }, [])



    const dispatch = useDispatch();


    const getAreas = async () => {
        await dispatch(GetAllAreas());
    }


    const handleDeleteArea = async (areaId) => {
        try {
            await dispatch(DeleteArea({ id: areaId }));
            setAreas(areas.filter(area => area.id !== areaId));
            setMapKey(prevKey => prevKey + 1);

            getAreas();
        } catch (error) {
            console.error("Error deleting area:", error);
        }
    };

    const handleUpdateArea = async (name, address, active, lat, lng, range, id) => {
        try {
            setAreas(prevAreas => {
                return prevAreas.map(area => {
                    if (area.id === id) {
                        // تحديث البيانات للمنطقة المطلوبة فقط
                        return {
                            ...area,
                            name: name,
                            address: address,
                            active: !active,
                            latitude: lat,
                            longitude: lng,
                            range: range
                        };
                    }
                    return area;
                });
            });
            await dispatch(UpdateArea({
                latitude: `${lat}`,
                longitude: `${lng}`,
                range: range,
                active: !active,
                id: id,
                name: name,
                address: address
            }))
        } catch (e) {
            console.log(e);
        } finally {

        }
    }




    const resAreas = useSelector(state => state.AreaReducer.AllAreas)


    useEffect(() => {
        if (resAreas && resAreas.data) {
            setAreas(resAreas.data)
        }
    }, [resAreas])


    return (
        <div>


            <div className='font'>
                <div className='flexItem font py-3'>
                    <div>
                        <p>ادارة اماكن ومناطق الخدمة</p>
                    </div>
                    <div>
                        <Link to="/admin/add-area">
                            <button className='btn' style={{ color: "black", background: "#FECB44" }}>اضافة منطقة جديدة</button>
                        </Link>
                    </div>
                </div>
                {/* Search */}
                <div className='p-2'>
                    {resAreas && resAreas.data && (
                        <div className='p-2'>
                            <MapManage areas={resAreas.data} key={JSON.stringify(resAreas.data)} />
                        </div>
                    )}
                </div>
                <div className='mt-3'>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>اسم مميز للمكان او النطاق</th>
                                <th>العنوان علي الخريطة</th>
                                <th>حالة الاتاحة</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                areas ? (
                                    areas.map((area, index) => {
                                        console.log(area)
                                        return (
                                            <tr key={area.id}>
                                                <td>{index + 1}</td>
                                                <td>{area.name}</td>
                                                <td>{area.address}</td>
                                                {/* <td>{area.range}</td> */}
                                                {
                                                    area.active ? (
                                                        <td>متاح</td>
                                                    ) : (<td>غير متاح</td>)
                                                }
                                                <td>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={area.active}
                                                        onClick={() => handleUpdateArea(area.name, area.address, area.active, area.latitude, area.longitude, area.range, area.id)}
                                                    />
                                                </td>
                                                <td style={{ cursor: "pointer" }}>
                                                    <Link to={`/admin/edit-area/${area.id}`}>
                                                        <MdEdit />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <DeletedArea areaId={area.id} onDelete={handleDeleteArea} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : null
                            }

                        </tbody>
                    </Table>
                </div>






            </div>


        </div>
    )
}

export default Area
