



import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllBikers } from '../../redux/actions/Bikers';
import axios from "axios";
import CarIcon from '../../Images/Car.png';
import { format } from 'date-fns';
import { injectGlobal } from '@emotion/css';

const Map = () => {
    const dispatch = useDispatch();
    const [infowindow, setInfowindow] = useState(null);
    const [zoomIn, setZoomIn] = useState(false);
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [biks, setBiks] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(0); // State for forcing update

    const [count, setCount] = useState(0);
    const [fetchTimeout, setFetchTimeout] = useState(null);




    const bikers = useSelector(state => state.GetBikers.AllBikers);

    const fetchAllBikersDelayed = () => {
        if (fetchTimeout) clearTimeout(fetchTimeout);

        const timeout = setTimeout(() => {
            fetchAllBikers();
        }, 1000);

        setFetchTimeout(timeout);
    };

    useEffect(() => {
        fetchAllBikersDelayed();
    }, [bikers]);



    const addMarkers = async () => {
        // initMap();
        setForceUpdate(prevState => prevState + 1);

        // console.log(biks);
        if (bikers) {
            if (bikers.bikers) {
                const newMarkers = bikers.bikers
                    .filter(biker => biker.is_active === true)
                    .map(async biker => {
                        // console.log(biker.latitude, biker.longitude);
                        const markerData = {
                            id: biker.id,
                            position: { lat: biker.latitude, lng: biker.longitude },
                            title: `${biker.user.first_name}`,
                            user: biker.user,
                        };

                        const config = {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`
                            }
                        };

                        const currentDate = new Date();
                        const formattedDate = format(currentDate, 'yyyy-MM-dd');

                        try {
                            const response = await axios.get(`${process.env.REACT_APP_BASEURL}v1/biker/biker-orders/${biker.id}?date=${formattedDate}`, config);
                            markerData.orders = response.data.data;
                        } catch (error) {
                            console.error("Error fetching biker orders:", error);
                            markerData.orders = null;
                        }

                        return markerData;
                    });
                const resolvedMarkers = await Promise.all(newMarkers);
                setMarkers(resolvedMarkers);
                initMap();
            }

        }



    };


    const initMap = async () => {
        const newMap = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 13,
            center: { lat: 24.746986, lng: 46.651638 },
        });

        setMap(newMap);

        const poly = new window.google.maps.Polyline({
            strokeColor: '#000000',
            strokeOpacity: 1.0,
            strokeWeight: 3,
        });

        poly.setMap(newMap);
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
        script.async = true;
        script.defer = true;
        script.onload = initMap;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (zoomIn && infowindow && map) {
            map.setZoom(15);
            setZoomIn(false);
        }
    }, [zoomIn, infowindow, map]);

    // useEffect(() => {
    //     if (markers && map) {
    //         markers.forEach(marker => {
    //             const newMarker = new window.google.maps.Marker({
    //                 position: marker.position,
    //                 title: marker.title,
    //                 map: map,
    //                 icon: {
    //                     url: CarIcon,
    //                     scaledSize: new window.google.maps.Size(80, 80),
    //                 },
    //             });

    //             const infowindowContent = document.createElement('div');
    //             infowindowContent.className = 'font';
    //             infowindowContent.style = 'font-family: IBM Plex Sans Arabic; font-size: 14px; font-weight: bolder; line-height: 20px; letter-spacing: 0em; max-width: 300px; padding: 10px; height:800px';

    //             infowindowContent.innerHTML += `
    //                 <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">اسم البايكر</span>:${marker.user.first_name}</p>
    //                 <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">جوال البايكر</span>: ${marker.user.phone}</p>
    //             `;

    //             if (marker.orders) {
    //                 marker.orders.forEach((order, index) => {
    //                     if (order.status === "STARTED" || order.status === "BIKER_ARRIVED" || order.status === "BIKER_ON_THE_WAY") {
    //                         infowindowContent.innerHTML += `
    //                             <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">الاوردر رقم </span>${index + 1}</p>

    //                             <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;"> رقم الطلب :  </span>${order.number}</p>
    //                             <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;"> نوع السياره:  </span>${order.vehicle.brand.name_ar}</p>

    //                             <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">حالة الطلب</span>: ${order.status}</p>
    //                             <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">  وقت انشاء الطلب </span>${order.order_date}</p>
    //                             <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">عدد الغسلات الحاليه</span>:${order.wash_count_current}</p>
    //                             <hr/>
    //                         `;
    //                     }
    //                 });
    //             }

    //             const newInfowindow = new window.google.maps.InfoWindow({
    //                 content: infowindowContent,
    //             });

    //             newMarker.addListener('click', () => {
    //                 if (infowindow) {
    //                     infowindow.close();
    //                 }
    //                 newInfowindow.open(map, newMarker);
    //                 setInfowindow(newInfowindow);
    //                 setZoomIn(true);
    //                 if (map) {
    //                     map.panTo(newMarker.getPosition());
    //                 }
    //             });
    //         });
    //     }
    // }, [markers, map]);

    useEffect(() => {
        if (markers && map) {
            markers.forEach(marker => {
                const newMarker = new window.google.maps.Marker({
                    position: marker.position,
                    title: marker.title,
                    map: map,
                    icon: {
                        url: CarIcon,
                        scaledSize: new window.google.maps.Size(80, 80),
                    },
                });

                const infowindowContent = document.createElement('div');
                infowindowContent.className = 'font';
                infowindowContent.style = 'font-family: IBM Plex Sans Arabic; font-size: 14px; font-weight: bolder; line-height: 20px; letter-spacing: 0em; max-width: 300px; padding: 10px; height:800px';

                infowindowContent.innerHTML += `
                    <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">اسم البايكر</span>:${marker.user.first_name}</p>
                    <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">جوال البايكر</span>: ${marker.user.phone}</p>
                `;

                if (marker.orders) {
                    marker.orders.forEach((order, index) => {
                        if (order.status === "STARTED" || order.status === "BIKER_ARRIVED" || order.status === "BIKER_ON_THE_WAY") {
                            infowindowContent.innerHTML += `
                                <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">الاوردر رقم </span>${index + 1}</p>
                                
                                <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;"> رقم الطلب :  </span>${order.number}</p>
                                <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;"> نوع السياره:  </span>${order.vehicle.brand.name_ar}</p>
                            
                                <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">حالة الطلب</span>: ${order.status}</p>
                                <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">  وقت انشاء الطلب </span>${order.order_date}</p>
                                <p style="color:black; font-size:18px; font-weight:500"><span style="color:#828282;">عدد الغسلات الحاليه</span>:${order.wash_count_current}</p>
                                <hr/>
                            `;
                        }
                    });
                }

                const newInfowindow = new window.google.maps.InfoWindow({
                    content: infowindowContent,
                });

                newMarker.addListener('click', () => {
                    if (infowindow) {
                        infowindow.close();
                    }
                    newInfowindow.open(map, newMarker);
                    setInfowindow(newInfowindow);
                    setZoomIn(true);
                    if (map) {
                        map.panTo(newMarker.getPosition());
                    }
                });
            });
        }
    }, [markers, map]);


    const fetchAllBikers = async () => {
        await dispatch(GetAllBikers());
    };

    return (
        <div>
            <div>
                <p className='font fs-4'>الرئيسية (لوحة التحكم)</p>
            </div>
            <div className='bickerMab bg-light mt-3'>
                <div className='d-flex justify-content-between align-items-center p-3'>
                    <div>
                        <p>تتبع البايكرز</p>
                    </div>
                    <div>
                        <button onClick={addMarkers} className='btn refresh'>تحديث المزامنة</button>
                    </div>
                </div>
            </div>

            <div id="map" className='mt-3' style={{ height: '500px', width: '100%' }}>

            </div>
        </div>
    );
};

export default Map;
