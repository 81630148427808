export const GET_LOGIN_USER = "GET_LOGIN_USER";
export const GET_STATICS = 'GET_STATICS'
export const GET_ALL_BIKERS = 'GET_ALL_BIKERS'
export const GET_ALL_BIKERS_DATA = 'GET_ALL_BIKERS_DATA'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_BIKERS = 'GET_ORDERS_BIKERS'
export const GET_ALL_MANAGE_BIKERS = 'GET_ALL_MANAGE_BIKERS'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const GET_ERRORS = 'GET_ERRORS'
export const DELETE_BIKER = 'DELETE_BIKER'
export const BIKER_PROFILE = 'BIKER_PROFILE'
export const PROFILE_UPDATE_BIKER = 'PROFILE_UPDATE_BIKER'
export const PROFILE_UPDATE_BIKER_DATA = 'PROFILE_UPDATE_BIKER_DATA'
export const ADD_NEW_BIKER = 'ADD_NEW_BIKER'
export const GET_ALL_USERS_CLIENTS = 'GET_ALL_USERS_CLIENTS'
export const GET_ALL_USERS_ADMINS = 'GET_ALL_USERS_ADMINS'
export const DELETE_USERS = "DELETE_USERS"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const GET_ALL_SLOTS = "GET_ALL_SLOTS"
export const ADD_NEW_SLOT = "ADD_NEW_SLOT"
export const DELETE_SLOT = "DELETE_SLOT"
export const UPDATE_SLOT = "UPDATE_SLOT"
export const GET_VARIABLE = "GET_VARIABLE"
export const UPDATE_VARIABLE = "UPDATE_VARIABLE"
export const GET_ALL_ORDERS_ADMIN = "GET_ALL_ORDERS_ADMIN"
export const GET_ALL_SUBS = "GET_ALL_SUBS"
export const ASSIGN_BIKER = "ASSIGN_BIKER"
export const GET_AVAILABEL_BIKERS_TO_ASSIGN = "GET_AVAILABEL_BIKERS_TO_ASSIGN"
export const CANCEL_ORDER = "CANCEL_ORDER"
export const DELETE_SUBS = "DELETE_SUBS"
export const UPDATE_DATE_SUBS = "UPDATE_DATE_SUBS"
export const VERIFY_OTP = "VERIFY_OTP"
export const SEND_OTP = "SEND_OTP"
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS"
export const GET_ALL_NOTIFICATIONS_BAQA = "GET_ALL_NOTIFICATIONS_BAQA"
export const GET_ALL_NOTIFICATIONS_BAQA_END = "GET_ALL_NOTIFICATIONS_BAQA_END"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const GET_ALL_APP_CONSTANTS = "GET_ALL_APP_CONSTANTS"
export const UPDATE_ALL_APP_CONSTANTS = "UPDATE_ALL_APP_CONSTANTS"
export const UPDATE_NOTIFICATION_ACTIVE = "UPDATE_NOTIFICATION_ACTIVE"
export const UPDATE_SCHUDLE = "UPDATE_SCHUDLE"
export const GET_ALL_AREAS = "GET_ALL_AREAS"
export const UPLOAD_NEW_AREA = "UPLOAD_NEW_AREA"
export const GET_SINGLE_AREA = "GET_SINGLE_AREA"
export const UPDATE_AREA = "UPDATE_AREA"
export const DELETE_AREA = "DELETE_AREA"
export const GET_ALL_SERVICES = "GET_ALL_SERVICES"
export const CREATE_SERVICES = "CREATE_SERVICES"
export const DELETE_SERVICE = "DELETE_SERVICE"
export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const SEARCH_BIKER = "SEARCH_BIKER"
export const GET_ALL_CARS = "GET_ALL_CARS"
export const GET_ALL_BRANDS = "GET_ALL_BRANDS"
export const ADD_NEW_BRAND = "ADD_NEW_BRAND"
export const ADD_NEW_BRAND_CAR_MODAL = "ADD_NEW_BRAND_CAR_MODAL"
export const DELETE_BRAND_CAR_MODAL = "DELETE_BRAND_CAR_MODAL"
export const UPDATE_BRAND_CAR_MODAL = "UPDATE_BRAND_CAR_MODAL"
export const GET_UPDATE_DATA = "GET_UPDATE_DATA"
export const GET_ALL_COLORS = "GET_ALL_COLORS"
export const ADD_NEW_COLOR = "ADD_NEW_COLOR"
export const DELETE_COLOR = "DELETE_COLOR"
export const UPDATE_COLOR = "UPDATE_COLOR"
export const GET_SINGLE_COLOR = "GET_SINGLE_COLOR"
export const GET_ALL_BAQA = "GET_ALL_BAQA"
export const CREATE_NEW_BAQA = "CREATE_NEW_BAQA"
export const DELETE_BAQA = "DELETE_BAQA"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_BAQA = "UPDATE_BAQA"
export const UPDATE_BAQA_DATA = "UPDATE_BAQA_DATA"
export const UPDATE_BAQA_DATA_ACTIVE = "UPDATE_BAQA_DATA_ACTIVE"
export const UPLOAD_PHOTO = "UPLOAD_PHOTO"
export const GET_ALL_GIFTS = "GET_ALL_GIFTS"
export const ADD_NEW_GIFT = "ADD_NEW_GIFT"
export const SETTING_GIFT = "SETTING_GIFT"
export const GET_ALL_ORDERS_STATUS = "GET_ALL_ORDERS_STATUS"
export const CHANGE_ROLE = "CHANGE_ROLE"
export const GET_ALL_POINTS = "GET_ALL_POINTS"
export const GET_ALL_POINTS_BIKER = "GET_ALL_POINTS_BIKER"
export const ADD_POINTS = "ADD_POINTS"
export const GET_ALL_SEARCH = "GET_ALL_SEARCH"
export const SETTING_POINTS = "SETTING_POINTS"
export const SPECIFIC_POINT = "SPECIFIC_POINT"
export const GET_ALL_BANNERS = "GET_ALL_BANNERS"
export const POST_NEW_BANNER = "POST_NEW_BANNER"
export const DELETE_BANNER = "DELETE_BANNER"
export const UPDATE_BANNER = "UPDATE_BANNER"
export const GET_ALL_CODES = "GET_ALL_CODES"
export const ADD_CODE = "ADD_CODE"
export const DELETE_CODE = "DELETE_CODE"
export const UPDATE_CODE = "UPDATE_CODE"

export const BIKER_ASSIGN = "BIKER_ASSIGN"
export const ORDER_CANCELED = "ORDER_CANCELED"
export const BIKER_ON_THE_WAY = "BIKER_ON_THE_WAY"
export const BIKER_ARRIVED = "BIKER_ARRIVED"
export const ORDER_START = "ORDER_START"
export const ORDER_COMPLETED = "ORDER_COMPLETED"
export const UPDATE_ACTIVE = "UPDATE_ACTIVE"
export const SEND_TO_PERSON = "SEND_TO_PERSON"
export const UPDATE_NOTIFY = "UPDATE_NOTIFY"
export const NOTIFICATION_USERS = "NOTIFICATION_USERS"
export const GET_SINGLE_PACKAGE = "GET_SINGLE_PACKAGE"
export const DELETE_SERVIES = "DELETE_SERVIES"
export const POST_NEW_SERVICES_PACKAGE = "POST_NEW_SERVICES_PACKAGE"
export const EDIT_COUNT_SERVICES = "EDIT_COUNT_SERVICES"
export const GET_SINGLE_CODE = "GET_SINGLE_CODE"
export const UPDATE_MAIN_CODE = "UPDATE_MAIN_CODE"
export const GET_ALL_INVOICES = "GET_ALL_INVOICES"
export const DOWNLOAD_INVOICE = "DOWNLOAD_INVOICE"
export const GET_SETTINGS_INVOICE = "GET_SETTINGS_INVOICE"
export const GET_SETTINGS_INVOICE_UPDATE = "GET_SETTINGS_INVOICE_UPDATE"
export const UPLADO_DATA = "UPLADO_DATA"
export const GET_ALL_SMS = "GET_ALL_SMS"
export const DELETE_SMS = "DELETE_SMS"
export const ADD_SMS = "ADD_SMS"
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS"
export const UPDATE_ACTIVE_RATE = "UPDATE_ACTIVE_RATE"
export const GET_ALL_PRIVACY = "GET_ALL_PRIVACY"
export const GET_ALL_TERMS = "GET_ALL_TERMS"
export const GET_ALL_QA = "GET_ALL_QA"
export const DELETE_REVIEW = "DELETE_REVIEW"
export const GET_ALL_SUPPORT = "GET_ALL_SUPPORT"
export const UPDATE_ALL_CONNECTS = "UPDATE_ALL_CONNECTS"
export const GET_ALL_SOCIAL = "GET_ALL_SOCIAL"
export const GET_ABOUT = "GET_ABOUT"
export const UPDATE_ABOUT = "UPDATE_ABOUT"
export const UPLADO_DATA_ABOUT = "UPLADO_DATA_ABOUT"
export const UPDATE_SOCIAL_MEDIA = "UPDATE_SOCIAL_MEDIA"
export const ADD_PREMISSION = "ADD_PREMISSION"
export const UPDATE_QA = "UPDATE_QA"
export const UPDATE_PRIVACY = "UPDATE_PRIVACY"
export const UPDATE_TERMS = "UPDATE_TERMS"
export const DELETE_SUPPORT = "DELETE_SUPPORT"
export const ADD_QA = "ADD_QA"
export const GET_ALL_BRANDS_DETAILS = "GET_ALL_BRANDS_DETAILS"
export const DELETE_BRAND = "DELETE_BRAND"
export const UPDATE_BRAND = "UPDATE_BRAND"
export const GET_SINGLE_BANNER = "GET_SINGLE_BANNER"