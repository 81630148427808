import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BikersList from './BikersList';
import Modal from 'react-bootstrap/Modal';
import ManageHolidays from './ManageHolidays';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { BikerProfileUpdate, GetAllBikers } from '../../redux/actions/Bikers';
import { ErrorMsg } from '../../Utils/Toast';
import ModalHoliday from './ModalHoliday';
import HolidayHistory from './HolidayHistory';
import NotificationComponent from '../Notification/NotificationComponent';
import NotificationBikers from '../Notification/NotificationBikers';


const AnimatedTabContent = ({ isActive, children }) => {
    const props = useSpring({
        opacity: isActive ? 1 : 0,
        transform: isActive ? 'translateY(0)' : 'translateY(20px)',
    });

    return <animated.div style={props}>{children}</animated.div>;
};


const ManageBiker = () => {


    //Dispatch Name Of Bikers
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllBikers());
    }, [dispatch])

    const BikersName = useSelector(state => state.GetBikers.AllBikers)


    // if (BikersName) {
    //     console.log(BikersName.bikers);
    // }








    // Animation
    const [activeTab, setActiveTab] = useState('home');
    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };




    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    //Modal Data
    const [selectedDays, setSelectedDays] = useState([]);

    const handleDays = (event) => {
        // Handle the selection of days and update the selectedDays state
        const selectedDay = event.target.value;
        if (!selectedDays.includes(selectedDay)) {
            setSelectedDays([...selectedDays, selectedDay]);
        }
    };
    const dayMapping = {
        "1": "الاتنين",
        "2": "الثلاثاء",
        "3": "الاربعاء",
        "4": "الخميس",
        "5": "الجمعه",
        "6": "السبت",
        "7": "الاحد",
    };

    const removeDay = (dayToRemove) => {
        // Remove the selected day from the array
        const updatedDays = selectedDays.filter((day) => day !== dayToRemove);
        setSelectedDays(updatedDays);
    };
    //Update Biker Profile 

    const [loading, setLoading] = useState(false);
    const [selectBiker, setBiker] = useState('');


    const handleUpdateProfile = async () => {
        try {
            setLoading(true);

            // Ensure selectedDays is an array
            if (!Array.isArray(selectedDays)) {
                console.error('Selected days must be an array.');
                return;
            }
            if (selectBiker === "" || selectedDays.length === 0) {
                // Display an error message or handle the case where biker or selectedDays is empty
                ErrorMsg('Data Missed');
                return;
            }

            await dispatch(BikerProfileUpdate({
                id: selectBiker,
                off_days: `${selectedDays.map((day) => day)}`,
            }));
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
            handleClose();
            setSelectedDays([]);
            setBiker('')
            dispatch(GetAllBikers())
        }
    };




    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }




    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة البايكرز</p>
                </div>
                <div>
                    {
                        activeTab === "home" ? (<Link to={'/admin/newbiker'}>
                            <button className='btn' style={{ color: "black", background: "#FECB44" }}>اضافة بايكر جديد</button>
                        </Link>) : activeTab === 'profile' ? (
                            (<button className='btn' onClick={handleShow} style={{ color: "black", background: "#FECB44" }}>اضافة عطلات دوريه</button>)
                        ) : null
                    }
                </div>
            </div>
            <div className='mt-4'>
                <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3 tabContainer"
                >
                    <Tab eventKey="home" className='font' title="قائمة البايكرز">
                        <AnimatedTabContent isActive={activeTab === 'home'}>
                            <BikersList />
                        </AnimatedTabContent>
                    </Tab>
                    <Tab eventKey="profile" className='font' title="ادارة الاجازات والعطلات">
                        <AnimatedTabContent isActive={activeTab === 'profile'}>
                            <ManageHolidays />
                            <ModalHoliday />
                            <HolidayHistory />
                        </AnimatedTabContent>
                    </Tab>
                    {
                        adminLogIn && adminLogIn.premessions ? (
                            adminLogIn.premessions.map((pre) => {
                                return (
                                    pre === "notification" ? (
                                        <Tab eventKey="notify" className='font' title="اشعارات البايكرز">
                                            <AnimatedTabContent isActive={activeTab === 'notify'}>
                                                {/* <NotificationComponent/> */}
                                                <NotificationBikers />
                                            </AnimatedTabContent>
                                        </Tab>
                                    ) : null
                                )
                            })
                        ) : null
                    }
                </Tabs>
            </div>


            {/* Modal */}
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>اضافة عطلات دورية للبايكر</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>يمكنك اختبار مدة وايام محددة بالتاريخ واليوم </span>
                    <div className='mt-3'>
                        <Form.Select aria-label="Default select example" style={{ background: "#EFF1F9" }} onClick={(e) => setBiker(e.target.value)}>
                            <option value="" hidden>حدد البايكر المراد اضافته</option>
                            {BikersName && BikersName.bikers ? (
                                BikersName.bikers
                                    .filter(biker => biker.is_active === true)
                                    .map((bikerName) => (
                                        <option key={bikerName.id} value={bikerName.user_id}>
                                            {bikerName.user.first_name}
                                        </option>
                                    ))
                            ) : (null)}
                        </Form.Select>
                    </div>
                    <div className='mt-3'>
                        <Form.Select aria-label="Default select example" onChange={handleDays} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>حدد الايام ( محلوظة يمكنك اختيار اكتر من يوم )</option>
                            <option value="1">الاتنين</option>
                            <option value="2">الثلاثاء</option>
                            <option value="3">الاربعاء</option>
                            <option value="4">الخميس</option>
                            <option value="5">الجمعه</option>
                            <option value="6">السبت</option>
                            <option value="7">الاحد</option>
                        </Form.Select>
                        <div className='mt-4 d-flex'>
                            {selectedDays.length > 0 && selectedDays.map((dayMaps) => (
                                <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
                                    {dayMapping[dayMaps]}
                                    <IoCloseSharp onClick={() => removeDay(dayMaps)} />
                                </p>
                            ))}
                        </div>
                    </div>

                    {/* <div className='mt-3'>
                        <span style={{ color: "#8B8D97" }}>المدة من</span>
                        <input type="date" className='w-100' name='dateFrom' id="dateFrom" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <span style={{ color: "#8B8D97" }}>المدة الي</span>
                        <input type="date" className='w-100' name='dateTo' id="dateTo" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div> */}
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleUpdateProfile}>
                        اضافه
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default ManageBiker
