import React, { useEffect, useState } from "react";
import { json, Link } from "react-router-dom";
import Logo from '../../Images/logo.png'
import swaped from '../../Images/Vector.png'
import Accordion from 'react-bootstrap/Accordion';
import { MdOutlineGroups } from "react-icons/md";
import { IoCarOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { IoBagOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { FaBookmark } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import useSidebar from "./Play";
import { TbNotes } from "react-icons/tb";
import { FaGifts } from "react-icons/fa6";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { RiAdvertisementFill } from "react-icons/ri";
import { MdDiscount } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { IoIosNotifications } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import { FaInfoCircle } from "react-icons/fa";
import { MdPolicy } from "react-icons/md";
import { LuAlertTriangle } from "react-icons/lu";
import { LuLogOut } from "react-icons/lu";

import { useNavigate } from 'react-router-dom';


import { useDispatch, useSelector } from 'react-redux'
// import GetStatics from '../../../redux/actions/StaticsRedux';
import GetStatics from '../../redux/actions/StaticsRedux';

const AdminSideBar = ({ defData }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const fetchData = async () => {
        await dispatch(GetStatics());
    }

    useEffect(() => {
        fetchData();
    }, [])


    const data = useSelector(state => state.StaticsReducer.Statics);


    const logOut = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        navigate("/")
    }


    const [defaultShow, setDefaultShow] = useState(defData)



    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }







    const { sidebarOpen, toggleSidebar } = useSidebar();
    return (
        <div className={`sidebar font text-dark ${sidebarOpen ? 'open' : ''}`}>
            <div className="d-flex flex-column p-2">
                <div className="d-flex justify-content-between align-items-center p-2">
                    <a href="/admin/dashboard">
                        <img src={Logo} className="w-50" alt="logo" />
                    </a>
                    <img src={swaped} alt="swabed" onClick={toggleSidebar} />
                </div>
                <div className="line"></div>
                <Link to="/admin/dashboard" style={{ textDecoration: "none" }}>
                    <div className="admin-side-text mt-3  p-2 mx-auto  ">
                        <span><FaHome style={{ marginLeft: "20px" }} />لوحة التحكم </span>
                    </div>
                </Link>
                <hr />
                <div>
                    <Accordion defaultActiveKey={"0"} style={{ marginTop: "10px", border: "none", outline: "none" }}>
                        <Accordion.Item eventKey="0" style={{ marginTop: "10px", border: "none" }}>
                            <Accordion.Header style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "10px", border: "none" }}>
                                <div className="admin-side-text header my-1 p-2 mx-auto  ">
                                    اداره التطبيق
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ marginTop: "10px", border: "none" }}>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "subscription" ? (<Link to="/admin/booking" style={{ textDecoration: "none" }}>
                                                    <div className="admin-side-text">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p><MdOutlineGroups size={"20px"} style={{ marginLeft: "3px" }} />
                                                                ادارة الاشتراكات والحجوزات </p>
                                                        </div>
                                                    </div>
                                                </Link>) : null
                                            )
                                        })
                                    ) : null
                                }
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "user" ? (
                                                    <Link to="/admin/users" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <MdOutlineGroups size={"20px"} style={{ marginLeft: "3px" }} />ادارة حسابات المستخدمين</p>
                                                                {
                                                                    data ? (
                                                                        data.statusCode === 200 ? (
                                                                            <p className="numSetting text-danger">{data.data.uesrsCount}</p>
                                                                        ) : (null)
                                                                    ) : (null)
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "vehicle" ? (
                                                    <Link to="/admin/cars" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <IoCarOutline size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة معلومات السيارة</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "service" ? (
                                                    <Link to="/admin/service" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <IoBagOutline size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    الخدمات الاضافية </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }

                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "slots" ? (
                                                    <Link to="/admin/slots" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <CiCalendar size={"20px"} style={{ marginLeft: "3px" }} /> ادارة الاوقات   (Slots) </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }








                                {
                                    adminLogIn && adminLogIn.permissions ? (
                                        adminLogIn.permissions.map((pre) => (
                                            pre === "biker" ? (
                                                <Link to="/admin/managebiker" style={{ textDecoration: "none" }}>
                                                    <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p> <CiUser size={"20px"} style={{ marginLeft: "20px" }} />ادارة البايكرز</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ) : null
                                        ))
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "biker" ? (
                                                    <Link to="/admin/managebiker" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <CiUser size={"20px"} style={{ marginLeft: "20px" }} />ادارة البايكرز</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }

                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "working-area" ? (
                                                    <Link to="/admin/areamanage" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1 p-2 mx-auto">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p><FaBookmark size={"20px"} style={{ marginLeft: "3px" }} />ادارة اماكن ومناطق الخدمة</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }





                                {/* <Link to="/admin/managebiker" style={{ textDecoration: "none" }}>
                                    <div className="admin-side-text my-1  p-2 mx-auto  ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p> <CiUser size={"20px"} style={{ marginLeft: "20px" }} />ادارة البايكرز</p>
                                        </div>
                                    </div>
                                </Link> */}


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>



                    <Accordion defaultActiveKey={"0"} style={{ marginTop: "10px", border: "none", outline: "none" }}>
                        <Accordion.Item eventKey="0" style={{ marginTop: "10px", border: "none", outline: "none" }}>
                            <Accordion.Header style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                <div className="admin-side-text header my-1 p-2 mx-auto   ">
                                    التسويق و المبيعات
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "package" ? (<Link to="/admin/baqu" style={{ textDecoration: "none" }}>
                                                    <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p> <TbNotes size={"20px"} style={{ marginLeft: "3px" }} />
                                                                الباقات</p>
                                                        </div>
                                                    </div>
                                                </Link>) : null
                                            )
                                        })
                                    ) : null
                                }
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "gift" ? (
                                                    <Link to="/admin/gift" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <FaGifts size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    الهدايا</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "point" ? (
                                                    <Link to="/admin/points" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <VscActivateBreakpoints size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة النقاط</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "banner" ? (
                                                    <Link to="/admin/ads" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <RiAdvertisementFill size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة الاعلانات</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }




                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "promo-code" ? (
                                                    <Link to="/admin/discount" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <MdDiscount size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة اكواد الخصم</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>




                    <Accordion defaultActiveKey={"0"} style={{ marginTop: "10px", border: "none", outline: "none" }}>
                        <Accordion.Item eventKey="0" style={{ marginTop: "10px", border: "none", outline: "none" }}>
                            <Accordion.Header style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                <div className="admin-side-text header my-1 p-2 mx-auto   ">
                                    الاعدادات والفوترة
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "order-invoice" ? (
                                                    <Link to="/admin/invoice" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <FaFileInvoiceDollar size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة الفواتير</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "sms" ? (
                                                    <Link to="/admin/managemessage" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <FaMessage size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة الرسايل النصية</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "notification" ? (
                                                    <Link to="/admin/notification" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <IoIosNotifications size={"20px"} style={{ marginLeft: "3px" }} />

                                                                    ادارة اشعارات النظام</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }



                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>



                    <Accordion defaultActiveKey={"0"} style={{ marginTop: "10px", border: "none", outline: "none" }}>
                        <Accordion.Item eventKey="0" style={{ marginTop: "10px", border: "none", outline: "none" }}>
                            <Accordion.Header style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                <div className="admin-side-text header my-1 p-2 mx-auto   ">
                                    التقيميات والتعليقات
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "review-order" ? (
                                                    <Link to="/admin/ratemanage" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <IoStar size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    ادارة التقيميات</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>



                    <Accordion defaultActiveKey={"0"} style={{ marginTop: "10px", border: "none", outline: "none" }}>
                        <Accordion.Item eventKey="0" style={{ marginTop: "10px", border: "none", outline: "none" }}>
                            <Accordion.Header style={{ marginTop: "10px", border: "none", outline: "none", textAlign: "right" }}>
                                <div className="admin-side-text header my-1 p-2 mx-auto   ">
                                    ادارة الصفحات
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ marginTop: "10px", border: "none", outline: "none" }}>

                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "support" ? (
                                                    <Link to="/admin/terms" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <BiSupport size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    الدعم والاسئلة الشائعة</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }



                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "about_us" ? (
                                                    <Link to="/admin/us" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <FaInfoCircle size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    معلومات عنا</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }


                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "privacy-policy" ? (
                                                    <Link to="/admin/policy" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <MdPolicy size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    السياسة والخصوصية</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }




                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "terms-conditions" ? (
                                                    <Link to="/admin/about" style={{ textDecoration: "none" }}>
                                                        <div className="admin-side-text my-1  p-2 mx-auto  ">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p> <LuAlertTriangle size={"20px"} style={{ marginLeft: "3px" }} />
                                                                    الشروط والاحكام</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                            )
                                        })
                                    ) : null
                                }




                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey={"0"} style={{ marginTop: "10px", border: "none", outline: "none" }}>
                        <Accordion.Item eventKey="0" style={{ marginTop: "10px", border: "none", outline: "none" }}>
                            <Accordion.Header style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                <div className="admin-side-text header my-1 p-2 mx-auto   ">
                                    الخروج
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ marginTop: "10px", border: "none", outline: "none" }}>
                                <Link to="/" style={{ textDecoration: "none" }}>
                                    <div className="admin-side-text my-1  p-2 mx-auto  ">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p onClick={logOut}><LuLogOut />تسجيل الخروج</p>
                                        </div>
                                    </div>
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>

    );
};

export default AdminSideBar;


