import React, { useEffect, useState } from 'react';
import HolidayHistory from './HolidayHistory';
import Table from 'react-bootstrap/Table';
import Pagination from '../../Utils/Pagination';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllBikers } from '../../redux/actions/Bikers';
import Form from 'react-bootstrap/Form';
import ModalHoliday from './ModalHoliday';
import UpdateOffDays from '../Modals/UpdateOffDays';
import DeleteOffDays from '../Modals/DeleteOffDays';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const ManageHolidays = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllBikers());
    }, [dispatch])
    const BikersName = useSelector(state => state.GetBikers.AllBikers)




    //Handle Data 


    var adminLogin = {};

    if (localStorage.getItem('user')) {
        adminLogin = JSON.parse(localStorage.getItem('user'));
    }




    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };



    return (
        <div>
            <div className='bickerMab  mt-3' style={{ background: "white" }}>
                <div className='p-3'>
                    <div>
                        <p>عطلات البايكرز الدورية</p>
                    </div>
                    {/* <DateTime onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                    {selectedDate && (
                        <p>
                            تم اختيار التاريخ والوقت: {moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}
                        </p>
                    )} */}
                </div>
            </div>


            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>اسم البايكر</th>
                            <th>الايام المحددة</th>
                            {/* <th>المدة من</th>
                            <th>المدة الي</th> */}
                            <th>المسؤول</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            BikersName && BikersName.bikers ? (
                                BikersName.bikers.map((biker, index) => {
                                    const weekdays = ["الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت", "الأحد"];
                                    const startDate = biker.in_active_start_date ? biker.in_active_start_date.split('T')[0] : 'لايوجد معاد';
                                    const endDate = biker.in_active_end_date ? biker.in_active_end_date.split('T')[0] : 'لايوجد معاد';

                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{biker.user.first_name} {biker.user.last_name}</td>
                                            {/* <td>{biker.user_id}</td> */}
                                            <td>
                                                {biker.off_days && biker.off_days.length > 0 ? (
                                                    biker.off_days.map((day, dayIndex) => (
                                                        <span key={dayIndex}>
                                                            {weekdays[parseInt(day) - 1]}
                                                            {dayIndex < biker.off_days.length - 1 && ", "}
                                                        </span>
                                                    ))
                                                ) : (
                                                    <span>No off days</span>
                                                )}
                                            </td>
                                            {/* <td>{startDate}</td>
                                            <td>{endDate}</td> */}
                                            <td>
                                                {adminLogin && adminLogin.first_name && adminLogin.first_name.trim() !== "" ? adminLogin.first_name : "اسم المسؤول غير متوفر"}
                                            </td>


                                            {
                                                biker.user.phone !== "user deleted" && biker.deleted_at === null ? (
                                                    <>
                                                        <td>
                                                            <UpdateOffDays selectID={biker.user_id} />
                                                        </td>
                                                        <td style={{ cursor: "pointer" }}>
                                                            <DeleteOffDays selectID={biker.user_id} />
                                                        </td>
                                                    </>
                                                ) : <td colSpan={2}></td>
                                            }
                                        </tr>
                                    );
                                })
                            ) : null
                        }
                    </tbody>
                </Table>
                <Pagination />
            </div>



        </div>
    )
}

export default ManageHolidays;



